<template>
  <Modal v-model="showModal" :mask-closable="false" width="800px">
    <div slot="header">选择地图位置</div>
    <div slot="footer">
      <div class="footer-content">
        <div class="footer-header">
          鼠标位置，经度：{{move.lng}} 纬度：{{move.lat}}
        </div>
        <div class="button-area">
          <div class="pos-area">
            <span>选择位置，纬度：</span>
            <!-- <span style="width: 150px">{{move.lat}}</span> -->
            <Input type="text" v-model="pos.lat" :maxlength="12" placeholder="请输入城市名称" style="width: 150px"></Input>
            <span>经度：</span>
            <!-- <span style="width: 150px">{{move.lng}}</span> -->
            <Input type="text" v-model="pos.lng" :maxlength="12" placeholder="请输入城市名称" style="width: 150px"></Input>
            <span>城市：</span>
            <Input type="text" v-model="pos.city" :maxlength="12" placeholder="请输入城市名称" style="width: 100px"></Input>
          </div>
          <Button class="btn-area" type="primary" style="margin-right: 8px" @click="ok">选定</Button>
          <Button class="btn-area" @click="cancel">关闭</Button>
        </div>
      </div>
    </div>
    <div class="map-container">
      <baidu-map class="map" :ak="ak" :key="showModal" :center="center" :map-type="mapType" :double-click-zoom="false" @ready="mapReady" :zoom="center.zoom" :scroll-wheel-zoom="true" @click="mapClick" @mousemove="mousemove">
        <bm-local-search v-if="map" :keyword="keyword"  :panel='false' :autoViewport='true'></bm-local-search>
        <bm-navigation v-if="map" anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-navigation>
        <bm-geolocation v-if="map" anchor="BMAP_ANCHOR_BOTTOM_RIGHT" :showAddressBar="true" :autoLocation="true"></bm-geolocation>
        <!-- <bm-city-list v-if="map" anchor="BMAP_ANCHOR_TOP_LEFT"></bm-city-list> -->
        <!-- <bm-geolocation anchor="BMAP_ANCHOR_BOTTOM_RIGHT" :showAddressBar="true" :offset="{width: 100, height: 20}" :autoLocation="true"></bm-geolocation> -->
        <bm-control v-if="map" :offset="{width: 10, height: 13}">
          <div class="map-tool-container">
            <div :class="['item', mapType == 'BMAP_NORMAL_MAP' ? 'active' : '']" @click="switchMapType('BMAP_NORMAL_MAP')">地图</div>
            <div :class="['item', mapType == 'BMAP_SATELLITE_MAP' ? 'active' : '']" @click="switchMapType('BMAP_SATELLITE_MAP')">卫星</div>
          </div>
        </bm-control>
        <bm-marker v-if="map" :key="0" :dragging="false" :position="{lng: pos.lng, lat: pos.lat}">
          <bm-label content="城市地图中心" :offset="{width: -60, height: 35}" :labelStyle="{width: '150px', textAlign:'center', backgroundColor: 'transparent', color: 'red', border: 0, fontSize : '12px'}" />
        </bm-marker>
        <bm-control :offset="{width: 120, height: 10}">
          <Input type="text" placeholder="搜索地址" v-model="keyword" style="width: 130px" ></Input>
        </bm-control>
      </baidu-map>
    </div>
  </Modal>
</template>
<script>
import {
  BaiduMap,
  BmLabel,
  BmControl,
  BmNavigation,
  BmMarker,
  BmGeolocation,
  // BmCityList,
  BmLocalSearch,
  // BmAutoComplete,
  // BmView
} from '@/components/vue-baidu-map/components'
export default {
  name: 'ModalSelectMapPoint',
  components: {
    BaiduMap,
    BmMarker,
    BmLabel,
    BmControl,
    BmNavigation,
    BmGeolocation,
    BmLocalSearch,
    // BmAutoComplete,
    // BmView,
    // BmCityList
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    custCenter: {
      type: Object,
      default() { return { area: '', lat: 22.770866, lng: 113.828333 } }
    }
  },
  data() {
    return {
      showModal: this.value,
      deviceType: 'all',
      keyword: '',
      location: '',
      mapType: 'BMAP_NORMAL_MAP',
      ak: 'MhPU9AQpbKZ0kyQf7bWAv7BvwoKOax88',
      map: null,
      BMap: null,
      mapStyle: {
        styleJson: require('@/assets/custom_map_config.json'),
      },
      center: {
        lng: 0,
        lat: 0,
        zoom: 12,
      },
      pos: {
        city: '',
        lng: 0,
        lat: 0
      },
      move: {
        area: '',
        lat: 0,
        lng: 0,
      }
    }
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if (newVal) {
        if (this.custCenter.lat > 0) {
          this.pos.lat = this.custCenter.lat;
          this.pos.lng = this.custCenter.lng;
          this.pos.city = this.custCenter.area;
          this.center.lat = this.pos.lat;
          this.center.lng = this.pos.lng;

          this.move.lat = this.pos.lat;
          this.move.lng = this.pos.lng;
          // console.log('get center pos', this.center, this.pos)
        } else {
          this.pos.lat = 22.770866;
          this.pos.lng = 113.828333;
          this.pos.city = '深圳市';
          this.center.lat = 22.770866;
          this.center.lng = 113.828333;
        }
      }
    },
    showModal(val) {
      this.$emit('showChanged', val);
    },
  },
  computed: {
  },
  mounted: function () {
  },
  methods: {
    mousemove: function (e) {
      this.move.lat = e.point.lat;
      this.move.lng = e.point.lng;
    },
    mapClick: function (e) {
      this.pos.lat = e.point.lat;
      this.pos.lng = e.point.lng;
      this.pos.city = '';
      var geoc = new this.BMap.Geocoder();
      let that = this;
      geoc.getLocation(e.point,
        function (rs) {
          that.pos.city = rs.addressComponents.city;
        });
    },
    switchMapType: function (type) {
      this.mapType = type;
    },
    mapReady: function (params) {
      this.map = params.map;
      this.BMap = params.BMap;
      this.map.setMapStyleV2({
        styleJson: this.mapStyle.styleJson
      })
      this.getMapCenter();
    },
    getMapCenter: async function () {
      this.center.lat = this.pos.lat;
      this.center.lng = this.pos.lng;
    },
    cancel() {
      this.showModal = false;
    },
    ok() {
      if (this.pos.city == '') {
        this.$Message.warning('还未获取到城市名，请稍等');
        return;
      }
      this.$emit('posSelected', this.pos);
      this.showModal = false;
    }
  }
}
</script>
<style scoped>
.footer {
  display: flex;
}
.footer-content {
  /* border: solid 1px red; */
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
}
.footer-header {
  /* border: solid 1px red; */
  height: 50px;
  flex: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.button-area {
  /* border: solid 1px red; */
  height: 50px;
  flex: auto;
  display: flex;
  align-items: center;
}
.pos-area {
  width: 50px;
  flex: auto;
  display: flex;
  align-items: center;
  align-content: flex-start;
  text-align: left;
}
.btn-area {
  width: 60px;
  flex: none;
}
.map-container {
  width: 100%;
  height: 500px;
  position: relative;
  padding: 0;
}
.map {
  width: 100%;
  height: 100%;
}
.map-tool-container {
  display: flex;
  border-radius: 3px;
  height: 26px;
}
.map-tool-container .item {
  width: 50px;
  height: 26px;
  background-color: white;
  color: black;
  cursor: pointer;
  border: solid 1px #1b5fa8;
  text-align: center;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 26px;
}
.map-tool-container .active {
  background-color: #1b5fa8;
  color: white;
}

.mapSearch {
  height: 24px;
  line-height: 24px;
}
</style>