<template>
  <div :key="itemIndex">
    <div>
       模式<Select v-model="form.mode" style="width:80px">
        <Option :value="0">未启用</Option>
        <Option :value="1">经纬度</Option>
        <Option :value="2">时钟</Option>
      </Select>
      <span v-if="form.mode==1" class="ml5">
        <Select v-model="form.on" style="width:90px">
          <Option :value="0">日落时间</Option>
          <Option :value="1">日出时间</Option>
        </Select>
      </span>
      <span v-if="form.mode==2" class="ml5">
        时间:<TimePicker style="width:80px" v-model="min" format="HH:mm" :placeholder="showLang('com.date.select.time')" :key="itemIndex"></TimePicker>
      </span>
    </div>
    <div>
      <div v-if="form.mode==1">
        <Select v-model="form.off" style="width:80px">
          <Option :value="0">无偏移</Option>
          <Option :value="1">延迟</Option>
          <Option :value="2">提早</Option>
        </Select>
        <span v-if="form.off!=0">
          偏移量<Input type="number" style="width:60px" :min="0" :max="100" number v-model="form.interval" placeholder="" />分
        </span>
      </div>
    </div>
    <div v-if="form.mode!=0">
      亮度<Input type="number" style="width:60px" :min="0" :max="100" number v-model="form.bright" placeholder="" />%
      联动<Input type="number" style="width:60px" :min="0" :max="100" number v-model="form.union" placeholder="" />%
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: 'TableBox',
  components: {
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    row: {
      type: Number,
      default: 1
    },
    showModal: {
      type: Boolean,
      default: false
    },
    item: {
      type: Array,
      default() { return []; }
    },
  },
  data() {
    return {
      // showModal: this.value,
      min: '00:00',
      itemIndex: 0,
      form: {
        "mode": 1, //工作模式：0-未启用，1-经纬度，2-时钟
        "on": 0, //经纬度时间类型：0-日落时间，1-日出时间
        "off": 0, //经纬度时间偏移：0-无偏移，1-延迟，2-提早
        "interval": 5, //由工作模式确定含义，工作模式为经纬度时：代表偏移量，工作模式为时钟时：代表从0点算起的时长，单位：分钟。
        "bright": 100, //调光值，0-100
        "union": 100 //联动调光值，0-100
      },
    }
  },
  watch: {
    showModal() {
      this.form = {};
      this.form = this.item[this.row];
      this.itemNum();
      this.itemIndex++;
    },
    min() {
      this.intervalNum();
    },
    'form.interval'() {
      this.intervalNum();
    },
    item() {
      this.$set(this, 'form', this.item[this.row]);
      this.itemNum();
    }
  },
  computed: {
    ...mapGetters('auth', ['funCodes', 'showLang']),
  },
  mounted: function () {
  },
  methods: {
    itemNum(){
      if (this.form.mode == 2) {//interval
        let a = Math.floor(this.form.interval / 60);
        let b = Math.floor(this.form.interval % 60);
        this.min = a + ':' + b;
      }
    },
    intervalNum() {
      if (this.form.mode == 1) {//min
        let a = Math.floor(this.form.interval / 60);
        let b = Math.floor(this.form.interval % 60);
        this.min = a + ':' + b;
      }
      if (this.form.mode == 2) {//interval
        let arr = this.min.split(":");
        this.form.interval = arr[0] * 60 + arr[1] * 1;
      }

    }
  }
}
</script>
<style scoped>
</style>