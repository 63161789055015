<template>
  <Modal v-model="showModal" :mask-closable="false" width="1800px">
    <div slot="header">{{showLang('timetable.edit.lamp.punctual')}}</div>
    <Form ref="form" :model="form" :label-width="70">
      <FormItem prop="name" :label="showLang('com.tab.title')" style="margin-bottom: 5px;width: 400px">
        <Input ref="name" type="text" maxlength="20" v-model="form.name" placeholder=""></Input>
      </FormItem>
      <FormItem :label-width="0">
        <table class="set-table" v-if="form.content" :key="formIndex">
          <tr>
            <td>{{showLang('com.date.period')}} 
              <Tooltip placement="top">
                <span class="red">!</span>
                <template #content>
                  <p>勾选将按照星期日的时段设置相同数据</p>
                </template>
              </Tooltip>
            </td>
            <td>{{showLang('com.date.sunday')}}</td>
            <td>{{showLang('com.date.mon')}}</td>
            <td>{{showLang('com.date.tues')}}</td>
            <td>{{showLang('com.date.wed')}}</td>
            <td>{{showLang('com.date.thurs')}}</td>
            <td>{{showLang('com.date.fri')}}</td>
            <td>{{showLang('com.date.sat')}}</td>
          </tr>
          <template v-for="m in 6">
            <tr :key="m">
              <td>
                <Checkbox v-model='checkboxList[m-1]' @on-change="changeCheckbox(m)">{{showLang('com.date.period')+m}}</Checkbox>
              </td>
              <td v-for="n in 7" :key="n" class="tableBox">
                <TableBox :item="form.content" :row="numIndex(m,n)"  :showModal='showModal'></TableBox>
              </td>
            </tr>
          </template>
        </table>
      </FormItem>
    </Form>
    <div slot="footer">
      <Button style="margin-right: 8px" @click="cancel">{{showLang('com.op.close')}}</Button>
      <Button type="primary" :loading="loading" @click="ok">{{showLang('com.op.save')}}</Button>
    </div>
  </Modal>
</template>
<script>
import { mapGetters } from 'vuex'

import TableBox from './TableBox'
export default {
  name: 'ModalLightTable',
  components: {
    TableBox
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default() { return {}; }
    },
  },
  data() {
    return {
      showModal: this.value,
      loading: false,
      tabs: [],
      isAdd: false,
      index: -1,
      formIndex: 0,
      rowIndex: 2,
      checkboxList: [false, false, false, false, false, false],
      form: {
        id: 0,
        name: '',
        content: [],
      },
    }
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if (this.showModal) {
        this.index = this.item.index;
        this.isAdd = this.item.isAdd;
        if (!this.item.isAdd) {
          this.form.id = this.item.data.id;
          this.form.name = this.item.data.name;
          this.$set(this.form, 'content', this.item.arr);
        } else {
          this.form.id = 0;
          this.form.name = '';
          this.form.content = [];
          let arr = {
            "mode": 1, //工作模式：0-未启用，1-经纬度，2-时钟
            "on": 0, //经纬度时间类型：0-日落时间，1-日出时间
            "off": 0, //经纬度时间偏移：0-无偏移，1-延迟，2-提早
            "interval": 5, //由工作模式确定含义，工作模式为经纬度时：代表偏移量，工作模式为时钟时：代表从0点算起的时长，单位：分钟。
            "bright": 100, //调光值，0-100
            "union": 100 //联动调光值，0-100
          }
          let arr1 = []
          for (let index = 0; index < 42; index++) {
            if (index % 6 == 0) {
              arr.mode = 1;
              arr.on = 1;
            } else if (index % 6 == 1) {
              arr.mode = 1;
              arr.on = 0;
            } else {
              arr.mode = 0;
              arr.on = 0;
            }
            arr1.push(JSON.parse(JSON.stringify(arr)))
          }
          this.$set(this.form, 'content', arr1);
        }
      }
    },
    showModal(val) {
      this.$emit('showChanged', val);
    }
  },
  computed: {
    ...mapGetters('auth', ['funCodes', 'showLang']),
  },
  mounted: function () {
  },
  methods: {
    changeCheckbox(num) {
      if(!this.checkboxList[num-1]){
        return false;
      }
      for (let index = 0; index < this.form.content.length; index++) {
        if (index % 6 == (num - 1)) {
           this.form.content[index]=JSON.parse(JSON.stringify(this.form.content[num-1]));
        }
      }
      this.form.content.push({});
      this.form.content.pop();
    },
    numIndex(m, n) {
      let num = 0;
      num = m + 6 * (n - 1) - 1
      return num;
    },
    getTableContent: function () {
      this.loading = true;
      this.$axios.post(`device/light/QueryPlanClockContent`, { id: this.form.id }).then(res => {
        this.loading = false;
        if (res.code !== 0) {
          return;
        }
        this.$set(this.form, 'content', res.data);
      })
    },
    ok: async function () {
      this.form.name = this.form.name.trim();
      if (!this.form.name || this.form.name.length > 20) {
        this.$Message.warning(this.showLang('com.save.err.len.name', 20));
        return;
      }
      this.loading = true;
      this.$axios.post(`device/light/SavePlanClock`, this.form).then(res => {
        this.loading = false;
        if (res.code == 0) {
          this.showModal = false;
          this.$emit('saved');
          this.$Message.success(this.showLang('com.ins.success'));
        }
      });
    },
    cancel() {
      this.showModal = false;
    },
  }
}
</script>
<style scoped>
.set-table tr td {
  border: solid 1px gray;
  text-align: center;
  white-space: nowrap;
}
.tableBox {
  width: 243px;
  height: 102px;
}
.set-table {
  width: 100%;
}
.time-span {
  display: flex;
}
.min-width {
  width: 90px;
  flex: none;
  margin: 0;
}
.ch-width {
  width: 120px;
  flex: none;
  margin: 0 10px;
  display: flex;
}
.ch-width span {
  width: 25px;
  flex: none;
}
.ch-width div {
  width: 40px;
  flex: auto;
}
.red {
  color: #f00;
  cursor: pointer;
}
</style>